import { Slot } from '@radix-ui/react-slot';
import type { HTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { cn } from '~/utils/cn';

interface StackProps extends HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
  direction?: 'vertical' | 'horizontal';
}

export const Stack = forwardRef<HTMLDivElement, StackProps>(
  ({ asChild, direction = 'horizontal', className, ...restProps }, ref) => {
    const Component = asChild ? Slot : 'div';
    return (
      <Component
        className={cn(
          'flex',
          {
            'flex-row': direction === 'horizontal',
            'flex-col': direction === 'vertical',
          },
          className,
        )}
        ref={ref}
        {...restProps}
      />
    );
  },
);

Stack.displayName = 'Stack';

export const VStack = forwardRef<HTMLDivElement, Omit<StackProps, 'direction'>>(
  (props, ref) => {
    return <Stack direction="vertical" ref={ref} {...props} />;
  },
);

VStack.displayName = 'VStack';

export const HStack = forwardRef<HTMLDivElement, Omit<StackProps, 'direction'>>(
  (props, ref) => {
    return <Stack direction="horizontal" ref={ref} {...props} />;
  },
);

HStack.displayName = 'HStack';

export const Spacer = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement>
>(({ className, ...restProps }, ref) => {
  return <div className={cn('flex-1', className)} ref={ref} {...restProps} />;
});

Spacer.displayName = 'Spacer';
